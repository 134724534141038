import React, { memo } from "react";
import CardProductHome from "./CardProductHome";
import CardRifle from "assets/CardProducts/CardRifle.png";
import CardCulatas from "assets/CardProducts/CardCulatas.png";
import CardAccesorios from "assets/CardProducts/CardAccesorios.png";
import { css } from "@emotion/css";
import { useIsResponsive } from "services/hooksGenerals";
import { useNavigate } from "react-router-dom";

const styles = {
  container: css`
    display: flex;
    height: auto;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `,
};

const ProductHome: React.FC = () => {
  const isResponsive = useIsResponsive();

  const navigate = useNavigate();

  const handleNavigation = (id: string): void => {
    navigate(`/products/${id}`);
  };
  return (
    <div className={styles.container}>
      <CardProductHome
        imgSrc={CardRifle}
        textButton="Rifles PCP y Nitro"
        onclick={(): void => handleNavigation("1")}
        style={{
          borderWidth: isResponsive ? "0px 1px 0px 0px" : "0px 1px 1px 1px",
        }}
      />
      <CardProductHome
        imgSrc={CardCulatas}
        textButton="kits Bullpup"
        onclick={(): void => handleNavigation("2")}
        style={{
          borderWidth: isResponsive ? "0px 1px 0px 1px" : "1px",
        }}
      />
      <CardProductHome
        imgSrc={CardAccesorios}
        textButton="Accesorios"
        onclick={(): void => handleNavigation("3")}
        bottom={false}
        style={{
          borderWidth: isResponsive ? "0px 0px 0px 1px" : "1px 1px 0px 1px",
        }}
      />
    </div>
  );
};

export default memo(ProductHome);
