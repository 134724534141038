import React, { memo } from "react";
import Image from "components/designLayouts/Image";
import ImageFooterLeft from "assets/Footer/FooterLeft.png";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import LinkYTBannerFooterButton from "components/LinkYTBannerFooterButton";

const style = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  subContainer: css`
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `,
  conteinerBottom: css`
    border-top: 1px solid ${palette.primary[5]};
    padding-top: 8%;
    @media (max-width: 768px) {
      padding-top: 15%;
    }
  `,
  image: css`
    width: auto;
    height: auto;
  `,
  containerText: css`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: auto;
    }
  `,
  containerTitle: css`
    display: flex;
    width: 65%;
    margin-top: 8%;
    @media (max-width: 768px) {
      width: 80%;
    }
  `,
  textTitle: css`
    font-size: 34px;
    font-weight: bold;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 26px;
    }
  `,
  containerMediumText: css`
    display: flex;
    width: 65%;
    margin-top: 5%;
    justify-content: center;
    @media (max-width: 768px) {
      width: 80%;
    }
  `,
  mediumText: css`
    font-size: 16px;
    color: ${palette.primary.def};
  `,
  containerLinkYT: css`
    margin-top: 5%;
    width: 65%;
    @media (max-width: 768px) {
      width: 80%;
      margin-bottom: 5%;
    }
  `,
};

const FooterLeft: React.FC = () => {
  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <div className={style.image}>
          <Image className={style.image} imgSrc={ImageFooterLeft} />
        </div>
        <div className={style.containerText}>
          <div className={style.containerTitle}>
            <p className={style.textTitle}>
              <span style={{ backgroundColor: palette.secondary.def }}>
                CADA ENTREGA ES CALIBRADA Y PROBADA
              </span>
            </p>
          </div>
          <div className={style.containerMediumText}>
            <p className={style.mediumText}>
              Testeamos cada producto para que llegue exactamente lo que querés,
              en las condiciones que tiene que estar. Así nos aseguramos de que
              apenas te llegue ya puedas comenzar a disfrutarlo.
            </p>
          </div>
          <LinkYTBannerFooterButton text="Mirá como lo hacemos acá" />
        </div>
      </div>
      <div className={style.conteinerBottom} />
    </div>
  );
};

export default memo(FooterLeft);
