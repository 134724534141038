import { css } from "@emotion/css";
import React, { memo, useMemo, useState } from "react";
import VernaLogin from "assets/Login/VernaLogin.png";
import Image from "components/designLayouts/Image";
import palette from "paletteColors.json";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { useIsResponsive } from "services/hooksGenerals";
import { useMutationLogin } from "services/ApiServices";

interface ICredentials {
  Username: string;
  Password: string;
}

const styles = {
  container: css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${palette.primary[1]};
  `,
  subContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 400px;
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
  `,
  containerForm: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 100%;
      height: 300px;
      margin-top: 5%;
    }
  `,
};

const Login: React.FC = () => {
  const IsResponsive = useIsResponsive();
  const [{ Username, Password }, setCredentials] = useState<ICredentials>({
    Password: "",
    Username: "",
  });

  const { isPending, mutate } = useMutationLogin();

  const disabledButton = useMemo<boolean>(
    () => !Username || !Password || isPending,
    [Username, Password, isPending]
  );

  const handleMutateLogin = (): void => {
    const params = {
      email: Username,
      password: Password,
    };
    mutate(params);
  };

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        {!IsResponsive && <Image imgSrc={VernaLogin} />}
        <div className={styles.containerForm}>
          <CustomInput
            Label="Usuario"
            value={Username}
            minLength={4}
            setValue={(newValue: string) =>
              setCredentials((prev: ICredentials) => ({
                ...prev,
                Username: newValue,
              }))
            }
          />
          <CustomInput
            Label="Contraseña"
            value={Password}
            minLength={4}
            Type="password"
            setValue={(newValue: string) =>
              setCredentials((prev: ICredentials) => ({
                ...prev,
                Password: newValue,
              }))
            }
          />
          <CustomButton
            Text="Iniciar Sesión"
            stylesContainer={{ width: IsResponsive ? "60%" : "100%" }}
            disabled={disabledButton}
            onClick={handleMutateLogin}
          />
        </div>
        {IsResponsive && <Image imgSrc={VernaLogin} />}
      </div>
    </div>
  );
};
export default memo(Login);
