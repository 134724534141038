import { css } from "@emotion/css";
import React, { memo } from "react";
import palette from "paletteColors.json";

interface ICustomButton {
  Text: string;
  stylesContainer?: React.CSSProperties | undefined;
  disabled?: boolean;
  onClick: () => void;
}

const styles = {
  container: css`
    height: 80px;
    @media (max-width: 768px) {
      height: 60px;
    }
  `,
  button: css`
    background-color: ${palette.secondary.def};
    height: 100%;
    width: 100%;
    border-radius: 40px;
    border: 1px solid ${palette.primary[5]};
  `,
  text: css`
    font-size: 22px;
    font-weight: bold;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
};

const CustomButton: React.FC<ICustomButton> = ({
  Text,
  stylesContainer,
  disabled = false,
  onClick,
}) => {
  return (
    <div className={styles.container} style={{ ...stylesContainer }}>
      <button
        className={styles.button}
        style={{ opacity: disabled ? 0.5 : 1 }}
        disabled={disabled}
        onClick={onClick}
      >
        <p className={styles.text}>{Text}</p>
      </button>
    </div>
  );
};

export default memo(CustomButton);
