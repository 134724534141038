import axios, { AxiosError } from "axios";

const customAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://192.168.1.50:8080/api/",
  headers: { "Content-Type": "application/json" },
});

customAxiosInstance.interceptors.response.use(
  (axiosResponse) => axiosResponse,
  (error: AxiosError) => {
    if (error.code === "ERR_NETWORK") {
      return Promise.reject({ Message: "Ocurrió un error de red!" });
    }
    return Promise.reject(error);
  }
);

export { customAxiosInstance };
