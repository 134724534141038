import { css } from "@emotion/css";
import { memo, useMemo } from "react";
import palette from "paletteColors.json";
import Image from "components/designLayouts/Image";
import NoImage from "assets/NoImage.png";
import { ReactComponent as EditImage } from "assets/EditImage.svg";
import { useIsResponsive } from "services/hooksGenerals";
import { IDataProducts } from "services/interfaces";
import { useSessionUser } from "services/useStore";

interface ICardProduct {
  product: IDataProducts;
  setSelectedRow: React.Dispatch<
    React.SetStateAction<IDataProducts | undefined>
  >;
  setOpenAddProduct: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenExpandProduct: React.Dispatch<React.SetStateAction<boolean>>;
}

const styles = {
  container: css`
    height: 25%;
    border-top: 1px solid ${palette.primary[5]};
  `,
  subContainer: css`
    height: 90%;
    border-bottom: 1px solid ${palette.primary[5]};
    display: flex;
    :hover {
      background-color: ${palette.primary[2]};
    }
    cursor: pointer;
  `,
  containerImage: css`
    width: 30%;
    height: 100%;
    border-right: 1px solid ${palette.primary[5]};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    @media (max-width: 768px) {
      width: 50%;
    }
  `,
  containerDescription: css`
    width: 70%;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      width: 50%;
    }
  `,
  subContainerDescription: css`
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 768px) {
      width: 90%;
    }
  `,
  textTitle: css`
    font-size: 24px;
    font-weight: bold;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 16px;
      padding-bottom: 0%;
    }
  `,
  textDescription: css`
    font-size: 18px;
    color: ${palette.primary.def};
  `,
  image: css`
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
  `,
  contaienerImageOpacity: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  `,
};

const CardProduct: React.FC<ICardProduct> = ({
  product,
  setSelectedRow,
  setOpenAddProduct,
  setOpenExpandProduct,
}) => {
  const isResposive = useIsResponsive();
  const user = useSessionUser();

  const titulo = useMemo<string>(() => product.name || "", [product.name]);

  const descripcion = useMemo<string>(
    () => product.description || "",
    [product.description]
  );

  const image = useMemo<string>(() => product.img[0] || NoImage, [product.img]);

  const handleSelectedRow = (): void => setSelectedRow(product);

  const handleEditProduct = (): void => {
    handleSelectedRow();
    setOpenAddProduct(true);
  };

  const handleExpandProduc = (): void => {
    handleSelectedRow();
    setOpenExpandProduct(true);
  };

  const handleSelectedProduct = (): void => {
    if (!user) return handleExpandProduc();
    handleEditProduct();
  };

  return (
    <div className={styles.container}>
      <div className={styles.subContainer} onClick={handleSelectedRow}>
        <div className={styles.containerImage} onClick={handleSelectedProduct}>
          <Image className={styles.image} imgSrc={image} />
          {user && (
            <div
              className={styles.contaienerImageOpacity}
              onClick={handleEditProduct}
            >
              <EditImage />
            </div>
          )}
        </div>
        <div
          className={styles.containerDescription}
          onClick={handleExpandProduc}
        >
          <div className={styles.subContainerDescription}>
            <p className={styles.textTitle}>{titulo}</p>
            {isResposive && (
              <p className={styles.textDescription}>{descripcion}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(CardProduct);
