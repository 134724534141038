import { Subject } from "rxjs";
import { useLayoutEffect, useState } from "react";
import { customAxiosInstance } from "./AxiosService";
import { jwtDecode } from "jwt-decode";

export interface User {
  uid: string;
  email: string;
  name: string;
  rol: string;
  iat: number;
  exp: number;
}
let currentUser: User | undefined;

const userSubject = new Subject<User | undefined>();

const decodeUser = (token: string): User => {
  const tokenDecoded: User = jwtDecode(token);
  return { ...tokenDecoded };
};

const updateSessionUser = (token: string): void => {
  currentUser = { ...decodeUser(token) };
  userSubject.next(currentUser);
  setCurrentToken(token);
  customAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const cleanSessionUser = (): void => {
  currentUser = undefined;
  userSubject.next(currentUser);
  clearToken();
  customAxiosInstance.defaults.headers.common.Authorization = "";
};

// Manejo session storage
const setCurrentToken = (token: string): void => {
  sessionStorage.setItem("token", token);
};

const clearToken = (): void => sessionStorage.removeItem("token");

const getCurrentToken = (): string | null => sessionStorage.getItem("token");

if (getCurrentToken()) {
  const currentToken = getCurrentToken();
  if (currentToken) updateSessionUser(currentToken);
}

const useSessionUser = (): User | undefined => {
  const [user, setUser] = useState(currentUser);
  useLayoutEffect(() => {
    const subscription = userSubject.subscribe((newState) => setUser(newState));
    return () => subscription.unsubscribe();
  }, []);

  return user;
};

export { decodeUser, useSessionUser, updateSessionUser, cleanSessionUser };
