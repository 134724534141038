import React, { memo, useState } from "react";
import Flex from "components/designLayouts/Flex";
import { css, keyframes } from "@emotion/css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as LogoVerna } from "assets/logoVerna.svg";
import { HiMenuAlt2 } from "react-icons/hi";
import palette from "paletteColors.json";
import { navBarList } from "constanst/constants";
import { useIsResponsive } from "services/hooksGenerals";

interface IHeader {
  id: number;
}

const bounce = keyframes`
  from {right: -100%}
  to {right: 0% }
`;

const styles = {
  container: css`
    background-color: "transparent";
    height: 60px;
    width: 100%;
    border-bottom: 1px solid ${palette.primary[5]};
    z-index: 50;
    position: absolute;
  `,
  flex: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-right: 5%;
    margin-left: 10%;
    @media (max-width: 768px) {
      margin-right: 0%;
    }
  `,
  logoVerna: css`
    width: 60%;
    object-fit: cover;
  `,
  containerMenu: css`
    display: flex;
    align-items: center;
    height: 100%;
    width: auto;
    border-left: 1px solid ${palette.primary[5]};
  `,
  navVar: css`
    height: 100%;
    width: auto;
    padding-right: 58px;
    padding-left: 58px;
    border-right: 1px solid ${palette.primary[5]};
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: 768px) {
      padding: 0px;
      border: 0px;
      display: flex;
      align-items: center;
    }
  `,
  hideMenu: css`
    display: inline-block;
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin-right: 30%;
    color: ${palette.primary[4]};
  `,
  containerIconMenu: css`
    height: 100%;
    align-items: center;
    display: flex;
  `,
  containerMenuOpen: css`
    background-color: ${palette.primary[1]};
    border: 1px solid ${palette.primary[5]};
    position: absolute;
    top: 100%;
    right: 0;
    animation: ${bounce} 1s;
  `,
  containerMenuClose: css`
    animation: ${bounce} 2s reverse;
  `,
  listadoMenu: css`
    display: flex;
    flex-direction: column;
    height: 60%;
  `,
  logoVernaMenu: css`
    position: absolute;
    bottom: 1%;
    right: 0;
  `,
  itemMenu: css`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid ${palette.primary[5]};
  `,
  textItem: css`
    margin-right: 10%;
    color: ${palette.primary[5]};
    font-size: 20px;
    font-weight: bold;
  `,
};

const Header: React.FC<IHeader> = ({ id }) => {
  const [sideNav, setSideNav] = useState<boolean>(false);
  const location = useLocation();
  const isResponsive = useIsResponsive();
  const handleSideNav = (): void => setSideNav(!sideNav);

  const handleCloseSideNav = (): void => setSideNav(false);

  return (
    <div className={styles.container}>
      <nav style={{ height: "100%" }}>
        <Flex className={styles.flex}>
          <>
            <Link to={"/"}>
              <LogoVerna className={styles.logoVerna} />
            </Link>
            <div style={{ height: "100%" }}>
              {isResponsive ? (
                <div className={styles.containerMenu}>
                  {navBarList.map((navBar) => (
                    <NavLink
                      key={navBar.id}
                      to={navBar.link}
                      className={styles.navVar}
                      style={{
                        backgroundColor:
                          navBar.id === id ? palette.primary[3] : "transparent",
                      }}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                      <p
                        style={{
                          color:
                            navBar.id === id
                              ? palette.primary[5]
                              : palette.primary[3],
                        }}
                      >
                        {navBar.title}
                      </p>
                    </NavLink>
                  ))}
                </div>
              ) : (
                <>
                  <div className={styles.containerIconMenu}>
                    <HiMenuAlt2
                      onClick={handleSideNav}
                      className={styles.hideMenu}
                    />
                  </div>
                  {sideNav && (
                    <div
                      className={styles.containerMenuOpen}
                      style={{
                        height: 0.94 * window.innerHeight,
                        width: 0.5 * window.innerWidth,
                      }}
                    >
                      <ul className={styles.listadoMenu}>
                        {navBarList.map((navBar) => (
                          <li
                            style={{ display: "flex", height: "100%" }}
                            key={navBar.id}
                          >
                            <NavLink
                              onClick={handleCloseSideNav}
                              to={navBar.link}
                              className={styles.itemMenu}
                              state={{
                                data: location.pathname.split("/")[1],
                              }}
                            >
                              <p className={styles.textItem}>{navBar.title}</p>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                      <LogoVerna className={styles.logoVernaMenu} />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        </Flex>
      </nav>
    </div>
  );
};
export default memo(Header);
