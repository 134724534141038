import palette from "paletteColors.json";
import { memo } from "react";
import Image from "./designLayouts/Image";
import { css } from "@emotion/css";
interface IIconsFooter {
  img: string;
  texto: string;
  bool?: boolean;
  onClick?: () => void;
}

const styles = {
  container: css`
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
      border-bottom: 1px solid ${palette.primary[5]};
    }
  `,
  image: css`
    margin-left: 10%;
  `,
  text: css`
    margin-left: 10%;
    width: 60%;
    font-size: 1.7vh;
    color: ${palette.primary[4]};
    font-weight: 400;
  `,
};

const IconFooter: React.FC<IIconsFooter> = ({
  img,
  texto,
  bool = true,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={styles.container}
      style={{
        borderRight: bool ? `1px solid ${palette.primary[5]}` : "0px",
      }}
    >
      <Image className={styles.image} imgSrc={img} />
      <p className={styles.text}>{texto}</p>
    </div>
  );
};

export default memo(IconFooter);
