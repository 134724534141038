import { css, keyframes } from "@emotion/css";
import { memo } from "react";
import palette from "paletteColors.json";
import { repeatStringNumTimes } from "services/hooksGenerals";

interface IDividerHome {
  color: string;
}

const scroll = keyframes`
from {transform: translateX(0) }
  to {transform: translateX(calc(0px - 50%));}
`;

const styles = {
  container: css`
    width: 100%;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid ${palette.primary[5]};
    @media (max-width: 768px) {
      height: 50px;
    }
  `,
  subContainer: css`
    margin: 0% 5% 0% 5%;
    height: 100%;
    border-left: 1px solid ${palette.primary[5]};
    border-right: 1px solid ${palette.primary[5]};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 768px) {
      margin: 0%;
    }
  `,
  scrollContainer: css`
    animation: ${scroll} 30s linear infinite;
    white-space: nowrap;
    height: 25px;
    @media (max-width: 768px) {
      height: 15px;
    }
  `,
  text: css`
    font-size: 20px;
    margin: 0px 10px 0px 10px;
    text-align: center;
    font-weight: bold;
    color: ${palette.primary[1]};
    @media (max-width: 768px) {
      font-size: 10px;
      height: 15px;
    }
  `,
};

const TEXT: string = "* EXPEIENCIA QUE AMPLIFICA TUS SENTIDOS * VERNA STOKS ";

const DividerHome: React.FC<IDividerHome> = ({ color }) => {
  return (
    <div className={styles.container} style={{ backgroundColor: color }}>
      <div className={styles.subContainer}>
        <div style={{ width: "97%", overflow: "hidden" }}>
          <div className={styles.scrollContainer}>
            <p className={styles.text}>{repeatStringNumTimes(TEXT, 10)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DividerHome);
