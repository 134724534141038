import { css } from "@emotion/css";
import { memo, useCallback } from "react";
import palette from "paletteColors.json";

interface ICustomInput {
  value: string;
  minLength?: number;
  Label?: string;
  Type?: React.HTMLInputTypeAttribute | undefined;
  stylesInput?: React.CSSProperties | undefined;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  setValue: (value: string) => void;
}

const styles = {
  textLabel: css`
    font-weight: bold;
    color: ${palette.primary.def};
    padding: 0% 0% 1% 8%;
    font-size: 22px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
  input: css`
    width: 100%;
    height: 80px;
    border-radius: 40px;
    background-color: ${palette.primary[7]};
    font-size: 20px;
    padding-left: 7%;
    padding-right: 7%;
    @media (max-width: 768px) {
      height: 60px;
    }
  `,
};

const CustomInput: React.FC<ICustomInput> = ({
  Label,
  Type = "text",
  value,
  minLength = 0,
  stylesInput,
  multiline = false,
  rows = 1,
  maxLength = 40,
  setValue,
}) => {
  const onChangeInput = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ): void => setValue(e.target.value),
    [setValue]
  );

  return (
    <div>
      {Label && <label className={styles.textLabel}>{Label}</label>}
      {!multiline && (
        <input
          type={Type}
          className={styles.input}
          style={stylesInput}
          value={value}
          onChange={onChangeInput}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {multiline && (
        <textarea
          value={value}
          onChange={onChangeInput}
          className={styles.input}
          style={stylesInput}
          rows={rows}
          maxLength={maxLength}
        />
      )}
    </div>
  );
};

export default memo(CustomInput);
