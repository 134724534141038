import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ICategories,
  IParamsCreateProducts,
  IParamsUpdateProducts,
  IProducts,
} from "./interfaces";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { Id, toast } from "react-toastify";
import { customAxiosInstance } from "./AxiosService";
import { updateSessionUser } from "./useStore";

//#region URLS
const getAllProducts = "products/GetAllProducts";
const getAllCategories = "categories/GetAllCategories";
const postLogin = "Auth/login";
const postCreateProducts = "products/CreateProduct";
const postDeleteProducts = "products/DeleteProductsById";
const postUpdateProducts = "products/UpdateProductsById";
//#endregion

/**
 * Listado de todos los productos
 * @param start numero de inicio del paginado
 * @param limit numero de fin del paginado
 * @returns listado de productos
 */
const useGetAllProducts = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getAllProducts"],
    queryFn: (): Promise<IProducts> =>
      customAxiosInstance
        .get(getAllProducts)
        .then((response) => response.data)
        .catch(() =>
          toast.error(
            "Ocurrio un error al obtener los productos. Intente nuevamente!"
          )
        ),
  });
  return { data, isLoading, refetch };
};

/**
 * Listado de todos los productos
 * @param start numero de inicio del paginado
 * @param limit numero de fin del paginado
 * @returns listado de productos
 */
const useGetAllCategories = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getAllCategories"],
    queryFn: (): Promise<ICategories> =>
      customAxiosInstance
        .get(getAllCategories)
        .then((response) => response.data)
        .catch(() =>
          toast.error(
            "Ocurrio un error al obtener las categorias. Intente nuevamente!"
          )
        ),
  });
  return { data, isLoading, refetch };
};

/**
 * Realiza el inicio de sesión
 * @param email nombre de usuario
 * @param password contraseña
 * @returns token
 */
const useMutationLogin = () => {
  const navigation = useNavigate();
  const { isPending, mutate } = useMutation({
    mutationFn: (data: { email: string; password: string }): Promise<any> =>
      customAxiosInstance
        .post(postLogin, data)
        .then((response: AxiosResponse) => response.data),
    onSuccess: (response) => {
      updateSessionUser(response);
      navigation("/");
      toast.success("Inicio de sesion correcto!");
    },
    onError: (error: any) =>
      toast.error(
        error.response?.data.Message || "Usuario o contraseña incorrecto!"
      ),
  });
  return { isPending, mutate };
};

/**
 * Metodo para crear un producto
 * @param onClose funcion para cerrar el dialogo de creacion
 * @param refetch funcion para actualizar el listado de productos
 */
const useMutateCreateProduct = (onClose: () => void, refetch: () => void) => {
  const { isPending, mutate } = useMutation({
    mutationFn: (params: IParamsCreateProducts) =>
      customAxiosInstance.post(postCreateProducts, params),
    onSuccess: (): void => {
      refetch();
      onClose();
      toast.success("Producto agregado correctamente!");
    },
    onError: (error: any): Id => {
      if (error.response?.data.Code === 401)
        return toast.error(
          error.response?.data.Message ||
            "Sesión expirada. Inicie nuevamente sesión!"
        );
      return toast.error(
        error.response?.data.Message ||
          "Ocurio un error al agregar el producto!"
      );
    },
  });
  return { isPending, mutate };
};

/**
 * Metodo para borrar un producto
 * @param onClose funcion para cerrar el dialogo de creacion
 * @param refetch funcion para actualizar el listado de productos
 */
const useDeleteProduct = (onClose: () => void, refetch: () => void) => {
  const { isPending, mutate } = useMutation({
    mutationFn: (params: { id: string }) =>
      customAxiosInstance.post(postDeleteProducts, params),
    onSuccess: (): void => {
      refetch();
      onClose();
      toast.success("Producto borrado correctamente!");
    },
    onError: (error: any): Id => {
      if (error.response?.data.Code === 401)
        return toast.error(
          error.response?.data.Message ||
            "Sesión expirada. Inicie nuevamente sesión!"
        );
      return toast.error(
        error.response?.data.Message || "Ocurio un error al borrar el producto!"
      );
    },
  });
  return { isPending, mutate };
};

/**
 * Metodo para borrar un producto
 * @param onClose funcion para cerrar el dialogo de creacion
 * @param refetch funcion para actualizar el listado de productos
 */
const useUpdateProduct = (onClose: () => void, refetch: () => void) => {
  const { isPending, mutate } = useMutation({
    mutationFn: (params: IParamsUpdateProducts) =>
      customAxiosInstance.post(postUpdateProducts, params),
    onSuccess: (): void => {
      refetch();
      onClose();
      toast.success("Producto editado correctamente!");
    },
    onError: (error: any): Id => {
      if (error.response?.data.Code === 401)
        return toast.error(
          error.response?.data.Message ||
            "Sesión expirada. Inicie nuevamente sesión!"
        );
      return toast.error(
        error.response?.data.Message || "Ocurio un error al editar el producto!"
      );
    },
  });
  return { isPending, mutate };
};

export {
  useGetAllProducts,
  useMutationLogin,
  useMutateCreateProduct,
  useDeleteProduct,
  useUpdateProduct,
  useGetAllCategories,
};
