import { memo } from "react";

interface IImage {
  imgSrc: string | undefined;
  className?: string | undefined;
  styles?: React.CSSProperties | undefined;
}

const Image: React.FC<IImage> = ({ className, imgSrc, styles }) => {
  return <img className={className} style={styles} src={imgSrc} alt={imgSrc} />;
};
export default memo(Image);
