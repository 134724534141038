import React, { memo } from "react";
import Image from "components/designLayouts/Image";
import ImageFooter from "assets/Footer/Footer.png";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import IconsFooter from "components/IconsFooter";
import IconWsp from "assets/Footer/iconwsp.png";
import IconFb from "assets/Footer/iconfb.png";
import IconYt from "assets/Footer/iconyt.png";
import IconCell from "assets/Footer/iconcell.png";
import IconUser from "assets/Footer/iconuser.png";
import { cellNumber } from "constanst/constants";
import { useNavigate } from "react-router-dom";

const styles = {
  container: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  textContainer: css`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    border-top: 1px solid ${palette.primary[5]};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    @media (max-width: 768px) {
      overflow-y: auto;
      height: 200px;
      flex-direction: column;
    }
  `,
};

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const navigateLogin = (): void => navigate(`/login`);
  return (
    <div className={styles.container}>
      <Image className={styles.container} imgSrc={ImageFooter} />
      <div className={styles.textContainer}>
        <IconsFooter img={IconWsp} texto={cellNumber} />
        <IconsFooter img={IconFb} texto="VernaStocks" />
        <IconsFooter img={IconYt} texto="VernaStocks" />
        <IconsFooter img={IconCell} texto={cellNumber} />
        <IconsFooter
          img={IconUser}
          texto="Log In"
          bool={false}
          onClick={navigateLogin}
        />
      </div>
    </div>
  );
};

export default memo(Footer);
