import React, { memo, useMemo, useState } from "react";
import { css } from "@emotion/css";
import Image from "components/designLayouts/Image";
import Header from "home/Header/Header";
import palette from "paletteColors.json";
import ImageTopBar from "assets/ImageTopBar.png";
import { useIsResponsive } from "services/hooksGenerals";
import CardProduct from "./CardProduct";
import { useParams } from "react-router-dom";
import { useSessionUser } from "services/useStore";
import { useGetAllCategories, useGetAllProducts } from "services/ApiServices";
import _ from "lodash";
import { IDataCategories, IDataProducts, IProducts } from "services/interfaces";
import AddProduct from "./AddProduct";
import CustomButton from "components/CustomButton";
import { QueryObserverResult } from "@tanstack/react-query";
import { RotateLoader } from "react-spinners";
import ExpandProduct from "./ExpandProduct";

const styles = {
  container: css`
    height: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: ${palette.primary[3]};
  `,
  subContainer: css`
    margin: 0% 5% 0% 5%;
    border-left: 1px solid ${palette.primary[5]};
    border-right: 1px solid ${palette.primary[5]};
    @media (max-width: 768px) {
      margin: 0%;
    }
  `,
  image: css`
    height: 60px;
    width: 100%;
  `,
  containerHeaderList: css`
    height: 12%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `,
  textHeaderList: css`
    color: ${palette.primary[1]};
    font-weight: bold;
    font-size: 18px;
  `,
  containerLoader: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

const Products: React.FC = () => {
  const params = useParams();
  const user = useSessionUser();
  const isResponsive = useIsResponsive();
  const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);
  const [openExpandProduct, setOpenExpandProduct] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IDataProducts | undefined>(
    undefined
  );

  const idProduct = useMemo<number>(() => Number(params.id), [params]);

  const { data: dataCategories } = useGetAllCategories();

  const categories = useMemo<IDataCategories[]>(() => {
    if (!dataCategories) return [];
    return dataCategories.Data;
  }, [dataCategories]);

  const { data, isLoading, refetch } = useGetAllProducts();

  const arrayProduct = useMemo<IDataProducts[]>(() => {
    if (!data) return [];
    return data.Data;
  }, [data]);

  const productsByCategory = useMemo<IDataProducts[] | undefined>(() => {
    switch (idProduct) {
      case 1:
        return _.groupBy(arrayProduct, "category.name").RIFLES;
      case 2:
        return _.groupBy(arrayProduct, "category.name").CULATAS;
      case 3:
        return _.groupBy(arrayProduct, "category.name").ACCESORIOS;
    }
  }, [arrayProduct, idProduct]);

  const tipoProduct = useMemo<string>(
    () =>
      idProduct === 1 ? "Rifles" : idProduct === 2 ? "Culatas" : "Accesorios",
    [idProduct]
  );

  const titleLeft = useMemo<string>(
    () => (isResponsive ? `Lista de ${tipoProduct}` : tipoProduct),
    [isResponsive, tipoProduct]
  );

  const titleRigth = useMemo<string>(
    () =>
      isResponsive
        ? `Cantidad de resultados: ${productsByCategory?.length || "0"}`
        : `Total: ${productsByCategory?.length || "0"}`,
    [isResponsive, productsByCategory?.length]
  );

  const addProduct = useMemo<string>(
    () => (isResponsive ? "Agregar Producto" : "Agregar"),
    [isResponsive]
  );

  const idCategoria = useMemo<string>(
    () => categories?.map((a) => a._id)[idProduct - 1] || "",
    [categories, idProduct]
  );

  const nameCategoria = useMemo<string>(
    () => categories?.map((a) => a.name)[idProduct - 1] || "",
    [categories, idProduct]
  );

  const handleRefetch = (): Promise<QueryObserverResult<IProducts, Error>> =>
    refetch();

  const handleAddProduct = (): void => {
    setSelectedRow(undefined);
    setOpenAddProduct(true);
  };
  return (
    <>
      <div className={styles.container}>
        <Header id={idProduct} />
        <div className={styles.subContainer}>
          <Image className={styles.image} imgSrc={ImageTopBar} />
        </div>
      </div>
      <div
        className={styles.container}
        style={{ height: window.innerHeight - 60 }}
      >
        <div
          className={styles.subContainer}
          style={{ height: window.innerHeight - 60 }}
        >
          {isLoading ? (
            <div
              className={styles.containerLoader}
              style={{ height: window.innerHeight - 60 }}
            >
              <RotateLoader
                color={palette.primary.def}
                loading={isLoading}
                size={20}
                aria-label="BarLoader"
              />
            </div>
          ) : (
            <>
              <div className={styles.containerHeaderList}>
                <p className={styles.textHeaderList}>{titleLeft}</p>
                {user && (
                  <CustomButton
                    Text={addProduct}
                    stylesContainer={{ width: "25%" }}
                    onClick={handleAddProduct}
                  />
                )}
                <p className={styles.textHeaderList}>{titleRigth}</p>
              </div>
              <div style={{ height: "88%", overflowY: "scroll" }}>
                {productsByCategory?.map((product: IDataProducts) => (
                  <CardProduct
                    product={product}
                    setSelectedRow={setSelectedRow}
                    setOpenAddProduct={setOpenAddProduct}
                    setOpenExpandProduct={setOpenExpandProduct}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {openAddProduct && (
        <AddProduct
          onClose={(): void => setOpenAddProduct(false)}
          idCategoria={idCategoria}
          nameCategoria={nameCategoria}
          refetch={handleRefetch}
          selectedRow={selectedRow}
        />
      )}
      {openExpandProduct && (
        <ExpandProduct
          selectedRow={selectedRow}
          setOpenExpandProduct={setOpenExpandProduct}
        />
      )}
    </>
  );
};

export default memo(Products);
