const navBarList = [
  {
    id: 1,
    title: "Rifles",
    link: "/products/1",
  },
  {
    id: 2,
    title: "Culatas",
    link: "/products/2",
  },
  {
    id: 3,
    title: "Accesorios",
    link: "/products/3",
  },
  {
    id: 4,
    title: "Sobre Nosotros",
    link: "/sobreNosotros",
  },
  {
    id: 5,
    title: "Contactanos",
    link: "https://wa.link/br524e",
  },
];

const menuMotionUl = {
  initial: { y: 30, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  transition: { duration: 0.5 },
};

const linkWsp = "https://wa.link/br524e";
const linkFb = "https://m.facebook.com/profile.php?id=100073445086165";
const linkYt = "https://www.youtube.com/@vernastocks139";
const cellNumber = "+ 549 2612 510 137";

export { navBarList, menuMotionUl, linkWsp, linkFb, linkYt, cellNumber };
