import React, { ReactElement, memo } from "react";

interface IFlex {
  children: ReactElement;
  className: string | undefined;
}

const Flex: React.FC<IFlex> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default memo(Flex);
