import { css } from "@emotion/css";
import Image from "components/designLayouts/Image";
import { memo } from "react";
import palette from "paletteColors.json";
import NoImage from "assets/NoImage.png";
import Close from "assets/close.png";

const styles = {
  container: css`
    background-color: ${palette.primary[4]};
    height: 150px;
    width: 45%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  `,
  labelImage: css`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  image: css`
    max-width: 100%;
    max-height: 100%;
  `,
  deleteIconContainer: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
  `,
  deleteIcon: css`
    width: 100%;
    height: 100%;
  `,
};

interface IImageProduct {
  image: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ImageProduct: React.FC<IImageProduct> = ({
  image,
  onChange,
  onClick,
}) => {
  return (
    <div className={styles.container}>
      {!image ? (
        <>
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            multiple={false}
            onChange={onChange}
          />
          <label className={styles.labelImage} htmlFor="icon-button-file">
            <Image className={styles.image} imgSrc={NoImage} />
          </label>
        </>
      ) : (
        <button onClick={onClick}>
          <div className={styles.deleteIconContainer}>
            <Image className={styles.deleteIcon} imgSrc={Close} />
          </div>
          <Image className={styles.image} imgSrc={image} />
        </button>
      )}
    </div>
  );
};
export default memo(ImageProduct);
