import { memo, useMemo, useState } from "react";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import Image from "components/designLayouts/Image";
import Trash from "assets/Trash.png";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { Id, toast } from "react-toastify";
import {
  useDeleteProduct,
  useMutateCreateProduct,
  useUpdateProduct,
} from "services/ApiServices";
import { IDataProducts } from "services/interfaces";
import ImageProduct from "./ImageProduct";

interface IAddProduct {
  idCategoria: string;
  nameCategoria: string;
  selectedRow: IDataProducts | undefined;
  onClose: () => void;
  refetch: () => void;
}

interface IFormData {
  title: string;
  category: {
    id: string;
    name: string;
  };
  description: string;
  link: string;
}

const styles = {
  container: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  containerForm: css`
    background-color: ${palette.primary[3]};
    width: 50%;
    height: 90%;
    border: 2px solid ${palette.primary[5]};
    border-radius: 20px;
    padding: 3%;
    @media (max-width: 768px) {
      width: 95%;
      height: 95%;
    }
  `,
  subContainerForm: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  containerHeader: css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${palette.primary[5]};
  `,
  textHeader: css`
    font-size: 24px;
    font-weight: bold;
    color: ${palette.primary[1]};
  `,
  containerInputs: css`
    height: 100%;
    overflow-y: auto;
  `,
  containerButtons: css`
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid ${palette.primary[5]};
  `,
  buttonCancelar: css`
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  textCancelar: css`
    font-size: 22px;
    font-weight: bold;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
  textLabel: css`
    font-weight: bold;
    color: ${palette.primary.def};
    padding: 0% 0% 1% 8%;
    font-size: 22px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
  containerImage: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5%;
  `,
};

const AddProduct: React.FC<IAddProduct> = ({
  idCategoria,
  nameCategoria,
  selectedRow,
  onClose,
  refetch,
}) => {
  const initialData: IFormData = {
    title: selectedRow?.name || "",
    category: {
      id: idCategoria || "",
      name: nameCategoria || "",
    },
    description: selectedRow?.description || "",
    link: selectedRow?.urlwsp || "",
  };
  const [input, setInput] = useState<IFormData>(initialData);
  const [images, setImages] = useState<any>(selectedRow?.img || []);

  const onChangeImages = async (event: any) => {
    const file = event.target.files[0];

    if (!file)
      return toast.error("No se pudo procesar la imagen. Intente nuevamente!");

    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      let fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        base64: reader.result,
        file: file,
      };

      if (!fileInfo.base64)
        return toast.error(
          "No se pudo procesar la imagen. Intente nuevamente!"
        );

      if (!images[0]) {
        setImages([fileInfo.base64]);
      } else {
        setImages((prev: any) => [...prev, fileInfo.base64]);
      }
    };

    reader.onerror = (): Id =>
      toast.error("No se pudo procesar la imagen. Intente nuevamente!");
  };

  const removeFromImages = (imageName: string): void => {
    setImages((prevImages: string | any[]) => {
      if (Array.isArray(prevImages)) {
        const updatedImages = prevImages.filter(
          (img: string): boolean => img !== imageName
        );
        return updatedImages;
      }
      return prevImages === imageName ? [] : prevImages;
    });
  };

  const { isPending, mutate } = useMutateCreateProduct(onClose, refetch);

  const { isPending: isPendingDelete, mutate: mutateDelete } = useDeleteProduct(
    onClose,
    refetch
  );

  const { isPending: isPendingUpdate, mutate: mutateUpdate } = useUpdateProduct(
    onClose,
    refetch
  );

  const disabledButton = useMemo<boolean>(
    () =>
      !input.title ||
      !images.length ||
      isPending ||
      !input.category.id ||
      isPendingDelete ||
      isPendingUpdate,
    [input, images, isPending, isPendingDelete, isPendingUpdate]
  );

  const handleMutate = (): void => {
    if (selectedRow) {
      const params = {
        id: selectedRow._id,
        name: input.title,
        category: input.category.id,
        description: input.description,
        img: images,
        urlwsp: input.link,
      };
      return mutateUpdate(params);
    }
    const params = {
      name: input.title,
      category: input.category.id,
      description: input.description,
      img: images,
      urlwsp: input.link,
    };
    return mutate(params);
  };

  const handleDeleteProduct = (): void => {
    const params = { id: selectedRow?._id || "" };
    mutateDelete(params);
  };

  const titleHeader = useMemo<string>(
    () => (selectedRow ? "Editar" : "Agregar"),
    [selectedRow]
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerForm}>
        <div className={styles.subContainerForm}>
          <div className={styles.containerHeader}>
            <p className={styles.textHeader}>{titleHeader} Producto</p>
            {selectedRow && (
              <button disabled={isPendingDelete} onClick={handleDeleteProduct}>
                <Image imgSrc={Trash} />
              </button>
            )}
          </div>
          <div className={styles.containerInputs}>
            <CustomInput
              Label="Titulo"
              value={input.title}
              stylesInput={{
                backgroundColor: palette.primary[4],
                borderRadius: "15px",
              }}
              setValue={(newValue: string): void =>
                setInput((prev) => ({ ...prev, title: newValue }))
              }
              maxLength={50}
            />
            <CustomInput
              Label="Categoria"
              value={input.category.name}
              stylesInput={{
                backgroundColor: palette.primary[4],
                borderRadius: "15px",
              }}
              setValue={() => undefined}
            />
            <CustomInput
              Label="Contenido"
              value={input.description}
              multiline
              stylesInput={{
                backgroundColor: palette.primary[4],
                borderRadius: "15px",
                height: "auto",
              }}
              rows={5}
              maxLength={200}
              setValue={(newValue: string): void =>
                setInput((prev) => ({ ...prev, description: newValue }))
              }
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className={styles.textLabel}>Imagenes</p>
              <div className={styles.containerImage}>
                <ImageProduct
                  image={images[0]}
                  onChange={onChangeImages}
                  onClick={() => removeFromImages(images[0])}
                />
                <ImageProduct
                  image={images[1]}
                  onChange={onChangeImages}
                  onClick={() => removeFromImages(images[1])}
                />
              </div>
              <div className={styles.containerImage}>
                <ImageProduct
                  image={images[2]}
                  onChange={onChangeImages}
                  onClick={() => removeFromImages(images[2])}
                />
                <ImageProduct
                  image={images[3]}
                  onChange={onChangeImages}
                  onClick={() => removeFromImages(images[3])}
                />
              </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <CustomInput
                Label="Link"
                value={input.link}
                stylesInput={{
                  backgroundColor: palette.primary[4],
                  borderRadius: "15px",
                }}
                setValue={(newValue: string): void =>
                  setInput((prev) => ({ ...prev, link: newValue }))
                }
                maxLength={50}
              />
            </div>
          </div>
          <div className={styles.containerButtons} onClick={onClose}>
            <button className={styles.buttonCancelar}>
              <p className={styles.textCancelar}>Cancelar</p>
            </button>
            <CustomButton
              Text="Guardar"
              stylesContainer={{ width: "40%" }}
              disabled={disabledButton}
              onClick={handleMutate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(AddProduct);
