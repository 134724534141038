import { css } from "@emotion/css";
import { memo, useMemo, useState } from "react";
import palette from "paletteColors.json";
import { IDataProducts } from "services/interfaces";
import Image from "components/designLayouts/Image";
import ArrowUp from "assets/ArrowUp.png";
import Close from "assets/close.png";
import { Link } from "react-router-dom";
import { linkWsp } from "constanst/constants";
import { ReactComponent as Arrow } from "assets/Arrow.svg";
import { useIsResponsive } from "services/hooksGenerals";

const styles = {
  general: css`
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 100000000;
    right: 5%;
    @media (max-width: 768px) {
      right: 0%;
    }
  `,
  container: css`
    background-color: ${palette.primary[3]};
    width: 70%;
    height: 100%;
    z-index: 10000;
    border-left: 1px solid ${palette.primary[5]};
    border-right: 1px solid ${palette.primary[5]};
    @media (max-width: 768px) {
      right: 0%;
      width: 100%;
    }
  `,
  containerImage: css`
    height: 60%;
    border-bottom: 1px solid ${palette.primary[5]};
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
  `,
  containerTitle: css`
    height: 17%;
    border-bottom: 1px solid ${palette.primary[5]};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
    overflow: hidden;
    @media (max-width: 768px) {
      height: 12%;
      padding: 2%;
    }
  `,
  title: css`
    font-size: 30px;
    font-weight: bold;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 20px;
    }
  `,
  containerBottom: css`
    display: flex;
    flex-direction: row;
    height: 23%;
    @media (max-width: 768px) {
      height: 28%;
    }
  `,
  containerBottomLeft: css`
    width: 65%;
    border-right: 1px solid ${palette.primary[5]};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
    overflow: hidden;
    @media (max-width: 768px) {
      overflow-y: scroll;
      padding: 2%;
      width: 60%;
    }
  `,
  textDescription: css`
    font-size: 20px;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 16px;
    }
  `,
  containerBottomRigth: css`
    width: 35%;
    background-color: ${palette.secondary.def};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 40%;
    }
  `,
  containerImageButton: css`
    display: flex;
    justify-content: flex-end;
    padding: 5%;
    @media (max-width: 768px) {
      padding: 5%;
    }
  `,
  containerTextButton: css`
    padding-left: 10%;
    margin-bottom: 10%;
    @media (max-width: 768px) {
      margin-bottom: 5%;
      padding-left: 5%;
    }
  `,
  textButton: css`
    font-weight: bold;
    font-size: 18px;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 16px;
    }
  `,
  subContainerImage: css`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  containerButton: css`
    width: 10%;
    height: 20%;
    display: flex;
    align-items: center;
  `,
  buttonClose: css`
    position: absolute;
    top: 3%;
    right: 3%;
  `,
};

interface IExpandProduct {
  selectedRow: IDataProducts | undefined;
  setOpenExpandProduct: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExpandProduct: React.FC<IExpandProduct> = ({
  selectedRow,
  setOpenExpandProduct,
}) => {
  const isResponsive = useIsResponsive();
  const [showImagePosition, setShowImagePosition] = useState<number>(0);

  const title = useMemo<string>(
    () => selectedRow?.name || "",
    [selectedRow?.name]
  );

  const description = useMemo<string>(
    () => selectedRow?.description || "",
    [selectedRow?.description]
  );

  const image = useMemo<string>(
    () => selectedRow?.img[showImagePosition] || "",
    [selectedRow?.img, showImagePosition]
  );

  const countImage = useMemo<number>(
    () => selectedRow?.img.length || 0,
    [selectedRow?.img]
  );

  const linkwsp = useMemo<string>(
    () => selectedRow?.urlwsp || linkWsp,
    [selectedRow?.urlwsp]
  );

  const handleClose = (): void => setOpenExpandProduct(false);

  const handleClickNextImage = (): void => {
    if (showImagePosition === countImage - 1) return setShowImagePosition(0);
    return setShowImagePosition(showImagePosition + 1);
  };

  const handleClickPrevImage = (): void => {
    if (showImagePosition === 0) return setShowImagePosition(countImage - 1);
    return setShowImagePosition(showImagePosition - 1);
  };

  return (
    <div
      className={styles.general}
      style={{ top: 60, height: window.innerHeight - 60 }}
    >
      <div
        style={{ width: isResponsive ? "40%" : "0%" }}
        onClick={handleClose}
      />
      <div className={styles.container}>
        <div className={styles.containerImage}>
          <button className={styles.buttonClose} onClick={handleClose}>
            <Image imgSrc={Close} />
          </button>
          {countImage > 1 && (
            <button
              className={styles.containerButton}
              style={{ justifyContent: "flex-start" }}
              onClick={handleClickPrevImage}
            >
              <Arrow style={{ transform: "rotate(180deg)" }} />
            </button>
          )}
          <div className={styles.subContainerImage}>
            <Image imgSrc={image} />
          </div>
          {countImage > 1 && (
            <button
              className={styles.containerButton}
              style={{ justifyContent: "flex-end" }}
              onClick={handleClickNextImage}
            >
              <Arrow />
            </button>
          )}
        </div>
        <div className={styles.containerTitle}>
          <p className={styles.title}>{title}</p>
        </div>
        <div className={styles.containerBottom}>
          <div className={styles.containerBottomLeft}>
            <p className={styles.textDescription}>{description}</p>
          </div>
          <Link
            target="_blank"
            to={linkwsp}
            className={styles.containerBottomRigth}
          >
            <div className={styles.containerImageButton}>
              <Image imgSrc={ArrowUp} />
            </div>
            <div className={styles.containerTextButton}>
              <p className={styles.textButton}>Consulta por este</p>
              <p className={styles.textButton}>producto</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default memo(ExpandProduct);
