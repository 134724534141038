import { Link } from "react-router-dom";
import LinkYTBannerFooter from "assets/Footer/LinkYTBannerFooter.png";
import { linkYt } from "constanst/constants";
import Image from "./designLayouts/Image";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import { memo } from "react";

interface Props {
  text?: string;
}

const style = {
  containerLinkYT: css`
    margin-top: 5%;
    width: 65%;
    @media (max-width: 768px) {
      width: 80%;
      margin-bottom: 5%;
    }
  `,
  text: css`
    margin-left: 5%;
    text-decoration: underline;
    font-size: 1.7vh;
    font-weight: bold;
    color: ${palette.primary.def};
  `,
  subcontainer: css`
    display: flex;
    align-items: center;
  `,
};

const LinkYTBannerFooterButton: React.FC<Props> = ({
  text = "Instalarlos es fácil, mirá como acá",
}) => {
  return (
    <div className={style.containerLinkYT}>
      <Link target="_blank" to={linkYt}>
        <div className={style.subcontainer}>
          <Image imgSrc={LinkYTBannerFooter} />
          <p className={style.text}>{text}</p>
        </div>
      </Link>
    </div>
  );
};

export default memo(LinkYTBannerFooterButton);
