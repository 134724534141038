import Banner from "components/Banner/Banner";
import React, { memo } from "react";
import Header from "./Header/Header";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import DividerHome from "components/Divider/DividerHome";
import ProductHome from "./Products/ProductHome";
import CompromiseHome from "./Compromise/CompromiseHome";
import FooterRigth from "./Footer/FooterRigth";
import FooterLeft from "./Footer/FooterLeft";
import ContactUs from "./Footer/ContactUs";
import Footer from "./Footer/Footer";
import ImageFooterRigth1 from "assets/Footer/FooterRigth1.png";
import ImageFooterRigth2 from "assets/Footer/FooterRigth2.png";

const styles = {
  container: css`
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid ${palette.primary[5]};
    overflow: hidden;
  `,
  subContainer: css`
    margin: 0% 5% 0% 5%;
    border-left: 1px solid ${palette.primary[5]};
    border-right: 1px solid ${palette.primary[5]};
    @media (max-width: 768px) {
      margin: 0%;
    }
  `,
  mediumText: css`
    font-size: 16px;
    color: ${palette.primary.def};
  `,
};

const Home: React.FC = () => {
  return (
    <>
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[2] }}
      >
        <Header id={0} />
        <div className={styles.subContainer}>
          <Banner />
        </div>
      </div>
      <DividerHome color={palette.primary[2]} />
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[3] }}
      >
        <div className={styles.subContainer}>
          <ProductHome />
        </div>
      </div>
      <DividerHome color={palette.primary[3]} />
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[3] }}
      >
        <div className={styles.subContainer}>
          <CompromiseHome />
        </div>
      </div>
      <DividerHome color={palette.primary[3]} />
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[2] }}
      >
        <div className={styles.subContainer}>
          <FooterRigth
            title="FABRICAMOS CULATAS TIPO BULLPUP"
            descriptionContent={
              <p className={styles.mediumText}>
                Tenemos nuestra{" "}
                <span style={{ fontWeight: "bold" }}>fábrica</span> en la cual
                trabajamos sobre el diseño y la creación de culatas tipo
                bullpup. La instalación es muy sencilla, en{" "}
                <span style={{ fontWeight: "bold" }}>YouTube</span> tenemos
                videos tutoriales de como instalarla.
              </p>
            }
            imageFooter={ImageFooterRigth1}
          />
        </div>
      </div>
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[3] }}
      >
        <div className={styles.subContainer}>
          <FooterLeft />
        </div>
      </div>
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[2] }}
      >
        <div className={styles.subContainer}>
          <FooterRigth
            title="TENEMOS UN TRATO PERSONALIZADO"
            descriptionContent={
              <p className={styles.mediumText}>
                Si lo que te llega, es lo que esperabas, no es casualidad. Por
                eso queremos que nos cuentes qué estás buscando y nosotros nos
                encargamos de ofrecerte un producto a tu medida
              </p>
            }
            imageFooter={ImageFooterRigth2}
          />
        </div>
      </div>
      <DividerHome color={palette.primary[3]} />
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[3] }}
      >
        <div className={styles.subContainer}>
          <ContactUs />
        </div>
      </div>
      <div
        className={styles.container}
        style={{ backgroundColor: palette.primary[2] }}
      >
        <div className={styles.subContainer}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default memo(Home);
