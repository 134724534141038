import React, { memo } from "react";
import Image from "components/designLayouts/Image";
import Banner1 from "assets/Banners/Banner1.png";
import { ReactComponent as WhatsApp } from "assets/WhatsApp.svg";
import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as Youtube } from "assets/youtube.svg";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import { Link } from "react-router-dom";
import { linkFb, linkWsp, linkYt } from "constanst/constants";
import { useIsResponsive } from "services/hooksGenerals";
import { ReactComponent as IconoFlechaBanner } from "assets/IconoFlechaBanner.svg";

const styles = {
  container: css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  containerText: css`
    position: absolute;
    top: 20%;
    width: 32%;
    height: auto;
    margin-left: 10%;
    @media (max-width: 768px) {
      top: 35%;
      width: 60%;
      margin-left: 5%;
    }
    @media (max-width: 390px) {
      top: 45%;
      width: 90%;
      margin-left: 5%;
    }
  `,
  text: css`
    color: ${palette.primary[4]};
    font-size: 2vw;
    font-style: normal;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 390px) {
      font-size: 14px;
    }
  `,
  containerImages: css`
    display: flex;
    height: auto;
    width: auto;
  `,
  images: css`
    padding-right: 23.25px;
  `,
  textLorem: css`
    color: ${palette.primary[4]};
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  `,
  subContainer: css`
    height: auto;
    width: 35%;
    position: absolute;
    top: 60%;
    left: 27%;
    transform: translate(-50%, -50%);
  `,
  containerIconFlechaBanner: css`
    position: absolute;
    top: 10%;
    right: 10%;
    height: 150px;
    width: 150px;
    rotate: 90deg;
    @media (max-width: 768px) {
      top: 5%;
      right: 5%;
      height: 100px;
      width: 100px;
    }
  `,
};

const Banner: React.FC = () => {
  const isResponsive = useIsResponsive();
  return (
    <div className={styles.container}>
      <Image className={styles.container} imgSrc={Banner1} />
      <div className={styles.containerText}>
        <p className={styles.text}>Tienda de rifles de aire</p>
        <p className={styles.text}>comprimido y </p>
        <p className={styles.text}>fabricantes de culatas</p>
      </div>
      {isResponsive && (
        <>
          <div className={styles.containerIconFlechaBanner}>
            <IconoFlechaBanner />
          </div>
          <div className={styles.subContainer}>
            <div style={{ marginBottom: "5%" }}>
              <p className={styles.textLorem}>
                Somos una empresa familiar apasionada por lo que hace. Llevamos
                años en el negocio de los rifles de aire comprimido.
              </p>
            </div>
            <div className={styles.containerImages}>
              <Link target="_blank" to={linkWsp} className={styles.images}>
                <WhatsApp />
              </Link>
              <Link target="_blank" to={linkFb} className={styles.images}>
                <Facebook />
              </Link>
              <Link target="_blank" to={linkYt} className={styles.images}>
                <Youtube />
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Banner);
