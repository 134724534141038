import { css } from "@emotion/css";
import Image from "components/designLayouts/Image";
import React, { memo } from "react";
import ImageContactUs from "assets/Footer/ContactUs.png";
import palette from "paletteColors.json";
import { Link } from "react-router-dom";
import { linkWsp } from "constanst/constants";

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 0% 10% 0%;
    @media (max-width: 768px) {
      padding: 5% 0% 5% 0%;
    }
  `,
  subcontainer: css`
    width: 80%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      align-items: center;
      flex-direction: column;
    }
  `,
  containerLeft: css`
    width: 35%;
    @media (max-width: 768px) {
      width: auto;
    }
  `,
  textLeft: css`
    font-weight: bold;
    font-size: 40px;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 30px;
    }
  `,
  containerRight: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    @media (max-width: 768px) {
      width: auto;
      height: 180px;
      margin-top: 8%;
      align-items: center;
    }
  `,
  textRigth: css`
    font-size: 20px;
    color: ${palette.primary.def};
  `,
};

const ContactUs: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <div className={styles.containerLeft}>
          <p className={styles.textLeft}>
            EXPERIENCIA QUE APMLIFICA TUS SENTIDOS
          </p>
        </div>
        <div className={styles.containerRight}>
          <p className={styles.textRigth}>
            Somos especialistas y queremos compartir nuestra experiencia con
            vos.
          </p>
          <Link target="_blank" to={linkWsp}>
            <Image imgSrc={ImageContactUs} />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default memo(ContactUs);
