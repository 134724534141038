import { memo } from "react";
import Image from "components/designLayouts/Image";
import ImageTopBar from "assets/ImageTopBar.png";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import Header from "./Header/Header";
import { useIsResponsive } from "services/hooksGenerals";

const styles = {
  container: css`
    margin-left: auto;
    margin-right: auto;
    background-color: ${palette.primary[3]};
  `,
  subContainer: css`
    margin: 0% 5% 0% 5%;
    border-left: 1px solid ${palette.primary[5]};
    border-right: 1px solid ${palette.primary[5]};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media (max-width: 768px) {
      margin: 0%;
    }
  `,
  image: css`
    height: 60px;
    width: 100%;
    z-index: 10;
  `,
  containerTitle: css`
    display: flex;
    justify-content: center;
    background-color: ${palette.primary[3]};
  `,
  subContainerTitle: css`
    width: 50%;
    @media (max-width: 768px) {
      width: auto;
    }
  `,
  title: css`
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 24px;
    }
  `,
  textTitle: css`
    text-align: center;
    font-size: 20px;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
  textValor: css`
    color: ${palette.primary.def};
    font-size: 36px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  `,
  text: css`
    font-size: 16px;
    color: ${palette.primary.def};
  `,
};

const SobreNosotros: React.FC = () => {
  const isResponsive = useIsResponsive();
  return (
    <>
      <div className={styles.container}>
        <Header id={4} />
        <div className={styles.subContainer}>
          <Image className={styles.image} imgSrc={ImageTopBar} />
        </div>
      </div>
      <div
        className={styles.container}
        style={{
          height: window.innerHeight - 60,
        }}
      >
        <div
          className={styles.subContainer}
          style={{
            height: isResponsive ? window.innerHeight - 60 : "auto",
            overflowY: "scroll",
          }}
        >
          <div className={styles.containerTitle}>
            <div className={styles.subContainerTitle}>
              <p className={styles.title}>
                NUESTRO COMPROMISO ES CON CADA CLIENTE
              </p>
            </div>
          </div>
          <div className={styles.containerTitle}>
            <div style={{ width: "80%" }}>
              <p className={styles.textTitle}>
                Nos encargamos de la venta de rifles de aire comprimido y de la
                fabricación de culatas tipo bullpup. Creemos firmemente en la{" "}
                <span style={{ backgroundColor: palette.secondary.def }}>
                  responsabilidad y seriedad
                </span>{" "}
                como base de nuestro negocio. Nos consideramos afortunados de
                trabajar en algo que nos apasiona, lo cual fomenta nuestra{" "}
                <span style={{ backgroundColor: palette.secondary.def }}>
                  predisposición y trato personalizado
                </span>{" "}
                a cada persona interesada en este mundo de las armas de aire
                comprimido.
              </p>
            </div>
          </div>
          <div className={styles.containerTitle}>
            <p className={styles.textValor}>Valores</p>
          </div>
          <div className={styles.containerTitle}>
            <div style={{ width: "85%" }}>
              <p className={styles.text}>
                Creemos firmemente en la{" "}
                <span style={{ fontWeight: "bold" }}>
                  responsabilidad y seriedad
                </span>{" "}
                como base de nuestro negocio. Nos consideramos afortunados de
                trabajar en algo que nos{" "}
                <span style={{ fontWeight: "bold" }}>apasiona</span>, lo cual
                fomenta nuestra predisposición y{" "}
                <span style={{ fontWeight: "bold" }}>trato personalizado</span>{" "}
                a cada persona interesada en este mundo de las armas de aire
                comprimido. Queremos que la{" "}
                <span style={{ fontWeight: "bold" }}>
                  experiencia sea un proceso acompañado
                </span>
                , claro y que puedas finalizar tu compra adquiriendo un producto
                a un precio real y competente.
              </p>
            </div>
          </div>
          <div className={styles.containerTitle}>
            <p className={styles.textValor}>Misión</p>
          </div>
          <div className={styles.containerTitle}>
            <div style={{ width: "85%" }}>
              <p className={styles.text}>
                Como empresa familiar somos{" "}
                <span style={{ fontWeight: "bold" }}>fanáticos</span> de este
                rubro.
                <span style={{ fontWeight: "bold" }}>Somos usuarios</span> y
                vivimos de primera mano la experiencia, pudiendo así comprender
                perfectamente las necesidades de nuestros clientes. Por esta
                razón, desde hace ya varios años comenzamos a interiorizarnos en
                las tecnologías necesarias para transformar un proceso que solía
                ser puramente artesanal en algo de mayor accesibilidad, con
                mejores plazos de entrega, más variedad de diseños y una calidad
                superior. Este proceso es el de transformar cualquier rifle pcp
                a bullpup. No solo nos conformamos con eso, también tenemos una
                tienda donde podrán encontrar rifles de calidad, accesorios y
                todo lo relacionado con este mundo a precios competentes en el
                mercado.
              </p>
            </div>
          </div>
          <div className={styles.containerTitle}>
            <p className={styles.textValor}>Visión</p>
          </div>
          <div className={styles.containerTitle}>
            <div style={{ width: "85%" }}>
              <p
                className={styles.text}
                style={{ fontSize: "18px", textAlign: "center" }}
              >
                Como empresas buscamos ser{" "}
                <span style={{ fontWeight: "bold" }}>líderes</span> en el
                mercado nacional, tanto por la venta de rifles y accesorias,
                como por la fabricación de culatas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(SobreNosotros);
