import React, { memo } from "react";
import palette from "paletteColors.json";
import { css } from "@emotion/css";
import Image from "components/designLayouts/Image";
import { ReactComponent as CardFlechaButton } from "assets/CardProducts/CardFlechaButton.svg";
import { useIsResponsive } from "services/hooksGenerals";

interface ICardProductHome {
  imgSrc: string;
  textButton: string;
  bottom?: boolean;
  style?: React.CSSProperties | undefined;
  onclick: () => void;
}

const styles = {
  container: css`
    height: auto;
    width: auto;
    display: flex;
    @media (max-width: 768px) {
      justify-content: center;
    }
  `,
  subContainer: css`
    width: 90%;
    border-color: ${palette.primary[5]};
    border-style: solid;
    cursor: pointer;
    &:hover {
      background-color: ${palette.secondary.def};
    }
    @media (max-width: 768px) {
      width: auto;
    }
  `,
  containerButton: css`
    border-top: 2px solid ${palette.primary[5]};
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10%;
    @media (max-width: 768px) {
      padding-left: 8%;
      height: 100px;
    }
  `,
  text: css`
    font-weight: bold;
    font-size: 24px;
    color: ${palette.primary.def};
  `,
};

const CardProductHome: React.FC<ICardProductHome> = ({
  imgSrc,
  textButton,
  bottom = true,
  style,
  onclick,
}) => {
  const isResponsive = useIsResponsive();
  return (
    <div
      className={styles.container}
      style={{
        justifyContent: !isResponsive
          ? "center"
          : textButton === "kits Bullpup"
          ? "center"
          : textButton === "Accesorios"
          ? "flex-end"
          : "flex-start",
        marginBottom: bottom && !isResponsive ? "5%" : "0%",
      }}
    >
      <div className={styles.subContainer} style={style} onClick={onclick}>
        <Image imgSrc={imgSrc} />
        <div className={styles.containerButton}>
          <p className={styles.text}>{textButton}</p>
          <CardFlechaButton />
        </div>
      </div>
    </div>
  );
};
export default memo(CardProductHome);
