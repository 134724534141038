import { css } from "@emotion/css";
import React, { memo } from "react";
import palette from "paletteColors.json";
import { useIsResponsive } from "services/hooksGenerals";
import { ReactComponent as IconoFlechaBanner } from "assets/IconoFlechaBanner.svg";

const styles = {
  container: css`
    height: auto;
    width: 100%;
  `,
  containerSmallText: css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media (max-width: 768px) {
      height: auto;
      padding-top: 5%;
    }
  `,
  smallText: css`
    color: ${palette.primary[1]};
    text-align: center;
    font-size: 14px;
  `,
  containerMediumText: css`
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 10%;
  `,
  subContaierMediumText: css`
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
    }
  `,
  mediumText: css`
    color: ${palette.primary.def};
    font-size: 28px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
  containerBigText: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 4%;
    @media (max-width: 768px) {
      padding-top: 2%;
      height: auto;
    }
  `,
  bigText: css`
    color: ${palette.primary.def};
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  `,
  textOrange: css`
    background-color: ${palette.secondary.def};
  `,
  containerIconFlechaBanner: css`
    position: sticky;
    height: 150px;
    width: 150px;
  `,
};

const CompromiseHome: React.FC = () => {
  const isResponsive = useIsResponsive();
  return (
    <div className={styles.container}>
      {isResponsive && (
        <div className={styles.containerIconFlechaBanner}>
          <IconoFlechaBanner />
        </div>
      )}
      <div className={styles.containerSmallText}>
        <p className={styles.smallText}>
          Queremos que sepas más sobre nosotros, por eso te compartimos nuestros
          principales valores
        </p>
      </div>
      <div className={styles.containerBigText}>
        <p className={styles.bigText}>NUESTRO COMPROMISO ES</p>
        <p className={styles.bigText}>CON CADA CLIENTE</p>
      </div>
      <div className={styles.containerMediumText}>
        <div className={styles.subContaierMediumText}>
          <p className={styles.mediumText}>
            Nos encargamos de la{" "}
            <span className={styles.textOrange}>
              venta de rifles de aire comprimido
            </span>{" "}
            y de la fabricación de culatas tipo bullpup. Creemos firmemente en
            la responsabilidad y seriedad como base de nuestro negocio. Nos
            consideramos afortunados de trabajar en algo que nos apasiona, lo
            cual fomenta nuestra{" "}
            <span className={styles.textOrange}>
              predisposición y trato personalizado
            </span>{" "}
            a cada persona interesada en este mundo de las armas de aire
            comprimido
          </p>
        </div>
      </div>
    </div>
  );
};
export default memo(CompromiseHome);
