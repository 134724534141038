import { useEffect, useState } from "react";

/**
 * Function to repeat a text a certain number of times
 * @param text the text to repeat               string
 * @param times times the text was repeated     number
 * @returns string
 */
const repeatStringNumTimes = (text: string, times: number): string => {
  if (times > 0) return text.repeat(times);
  return "";
};

/**
 * Function to identify if the screen is less than 768px
 * @returns boolean
 */
const useIsResponsive = (): boolean => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // ----------MenuResponsive----------//
  const responsiveMenu = (): void => {
    if (window.innerWidth <= 768) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };
  useEffect((): void => {
    responsiveMenu();
    window.addEventListener("resize", responsiveMenu);
  }, []);

  return showMenu;
};

export { repeatStringNumTimes, useIsResponsive };
