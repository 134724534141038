import React, { ReactElement, memo } from "react";
import Image from "components/designLayouts/Image";
import { css } from "@emotion/css";
import palette from "paletteColors.json";
import { useIsResponsive } from "services/hooksGenerals";
import LinkYTBannerFooterButton from "components/LinkYTBannerFooterButton";

interface IFooterRigthProps {
  title: string;
  descriptionContent: ReactElement;
  imageFooter: string;
}

const style = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  subContainer: css`
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `,
  conteinerBottom: css`
    border-top: 1px solid ${palette.primary[5]};
    padding-top: 8%;
    @media (max-width: 768px) {
      padding-top: 15%;
    }
  `,
  image: css`
    width: auto;
    height: auto;
  `,
  containerText: css`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: auto;
    }
  `,
  containerTitle: css`
    display: flex;
    width: 65%;
    margin-top: 8%;
    @media (max-width: 768px) {
      width: 80%;
    }
  `,
  textTitle: css`
    font-size: 34px;
    font-weight: bold;
    color: ${palette.primary.def};
    @media (max-width: 768px) {
      font-size: 26px;
    }
  `,
  containerMediumText: css`
    display: flex;
    width: 65%;
    margin-top: 5%;
    justify-content: center;
    @media (max-width: 768px) {
      width: 80%;
    }
  `,
};

const FooterRigth: React.FC<IFooterRigthProps> = ({
  descriptionContent,
  title,
  imageFooter,
}) => {
  const isResponsive = useIsResponsive();
  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        {!isResponsive && (
          <div className={style.image}>
            <Image className={style.image} imgSrc={imageFooter} />
          </div>
        )}
        <div className={style.containerText}>
          <div className={style.containerTitle}>
            <p className={style.textTitle}>
              <span style={{ backgroundColor: palette.secondary.def }}>
                {title}
              </span>
            </p>
          </div>
          <div className={style.containerMediumText}>{descriptionContent}</div>
          <LinkYTBannerFooterButton text="Conocé más de nosotros acá" />
        </div>
        {isResponsive && (
          <div className={style.image}>
            <Image className={style.image} imgSrc={imageFooter} />
          </div>
        )}
      </div>
      <div className={style.conteinerBottom} />
    </div>
  );
};

export default memo(FooterRigth);
